.sidebar {
  padding-top: 0px !important;
  max-width: 100%;
  z-index: 9;
  position: relative;
  border-radius: 0 !important;
  padding-right: 30px;
  // background: radial-gradient(circle at top left,rgba(200,200,200,1) 0%, rgba(255,255,255,0) 40%);
  // background: linear-gradient(to right,rgba(200,200,200,1) 0%, rgba(255,255,255,0) 40%);
  // min-height: 100vh;

  .section {
    margin-top: 10px;
    &.first {
      margin-top: 15px;
    }

    // max-height: 300px;
    overflow-y: auto;
    // padding-bottom: 15px;
    .paper-container-left {
      // border-left: none;
    }
    .paper-container-right {
      padding-right: 15px;
      // border-right: none;
    }

    .section-container {
      min-width: 100%;
      width: max-content;
    }

    .sticky-right {
      position: sticky;
      right: 15px;
    }

    .sticky-left {
      position: sticky;
      left: 15px;
    }
  }

}

@media screen and (min-width: 992px) {
  .sidebar {
    max-width: 80%;
    padding-right: 0px;
  }
}

@media screen and (min-width: 1200px) {
  .sidebar {
    max-width: 55%;
    padding-right: 0px;
  }
}

.map-grid-container {
  position: absolute;
  top: 0;
  left: 85px;
  width: calc(100% - 85px);
  height: 100%;
  overflow: hidden;
  .map-overlay {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    pointer-events: none;
  }
}

.viewer-layout-container {
  width: 100%;
  margin: auto;
  min-height: 100%;
}

