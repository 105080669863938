.header {
  grid-area: header;
  padding-top: 0px;
}

.footer {
  grid-area: footer;
  z-index: 2;
  padding: 20px;
}

.content {
  grid-area: content;
  min-width: 100%;
  min-height: 100%;
}

.latnav {
  grid-area: latnav;
  min-height: calc(1vh - 50px);
}

.topnav {
  grid-area: topnav;
  margin: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logo {
  grid-area: logo;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.wrapper {
    width: 100%;
    // max-width: 2048px;
    margin: auto;
    display: grid;
    min-height: 100vh;
    grid-gap: 0px;
    grid-template-columns: 85px 1fr;
    grid-template-rows: 60px minmax(calc(100vh - 120px), 1fr) 60px;
    grid-row-gap: 0;
    grid-template-areas:
      "logo content"
      "latnav content"
      "latnav footer";
}

.swagger-ui .wrapper {
  display: block;
  width: 100%;
  height: 100%;
  min-height: auto;
}

.box {
  border-radius: 5px;
  padding-left: 30px;
  font-size: 150%;
}