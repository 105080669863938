.leaflet-control-layers.leaflet-control{
    background-color:#00293E !important;  
    margin-left: 10px  !important;
    border: 0;
    color: #7d90a9;
    margin-top: 15px;
    box-shadow: 0 0 0 0 ;
    width: 81%;
    display: flex;
    justify-content: center;
}
.leaflet-control-layers.leaflet-control.leaflet-control-layers-expanded{
        height: 200px;
    background-color:white !important;  
}

/* 
.leaflet-layer,
.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-control-attribution {
  filter: invert(100%) hue-rotate(200deg) brightness(85%) contrast(100%);
} */